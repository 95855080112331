import React, { Suspense, lazy, useContext } from 'react';

import HomeHeader from '../components/home_header';
import './home.css';

export default function Home() {

  return (
        <div>
          <HomeHeader></HomeHeader>
          <section id="intro">
              <div className="container">
                <div className="center">
                  <img className="" style={{width: '100%'}} src="../img/yellow-truck.png" alt="Lunardi Truck" />
                </div>
              </div>

              <div className="container">
                <h1><span className="fifty-year">Providing over 60 years </span><span className="service">electircal service</span></h1>
                <p className="intro-text" role="main">Lunardi Electric has been providing premier electrical construction services in Sonoma County for over half a century. Joe Lunardi's reliability and integrity have made it the most sought-after company for over 60 years. Lunardi does approximately $12,000,000 a year in sales and is a union organization with a staff of over 40 electricians. Lunardi is also proud of it's 24-hour emergency services. Lunardi's staff consists of only the most highly qualified competent and courteous personnel. Lunardi uses only the most sophisticated and high-tech equipment to help them locate and repair the problem in the least amount of time with less inconvenience to the customer. Lunardi can deliver the most sophisticated equipment quickly, and there is no job too small to bid.</p>
              </div>
          </section>
          {/* <!-- Services Grid Section --> */}
          <section id="services" className="bg-light-gray">

            {/* <!-- Page Heading --> */}

            <div className="container">
                {/* <!-- Page Content --> */}
                <div className="custom-hr"></div>

                <h1 className="section-title">Services</h1> 

                <div className="container" role="list">
                    {/* <!-- Projects Row --> */}
                    <div className="service-image-row row">
                        <div className="col-md-3 col-sm-6 col-xs-12 portfolio-item" role="listitem">
                            <div className="service-image service-image-1"role="img" aria-label="Image showing, Large industrial warehouse project"></div>
                            <div className="service-title">Industrial</div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 portfolio-item" role="listitem">
                            <div className="service-image service-image-2" role="img" aria-label="Image showing, High voltage commercial project"></div>
                            <div className="service-title">Commercial</div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 portfolio-item" role="listitem">
                            <div className="service-image service-image-3" role="img" aria-label="image showing detailed plans"></div>
                            <div className="service-title">Design/Build</div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 portfolio-item" role="listitem">
                            <div className="service-image service-image-4" role="img" aria-label="image showing street light project"></div>
                            <div className="service-title">Street Lights</div>
                        </div>
                    </div>
                    {/* <!-- /.row --> */}
                    
                    {/* <!-- Projects Row --> */}
                    <div className="service-image-row row">
                        <div className="col-md-3 portfolio-item">
                            {/* <!-- <div className="service-image service-image-1"></div>
                            <div className="service-title">some text</div> --> */}
                        </div>
                        <div className="col-md-3 portfolio-item" role="listitem">
                            <div className="service-image service-image-5" role="img" aria-label="image showing a service technician working on a ladder"></div>
                            <div className="service-title">Service Work</div>
                        </div>
                        <div className="col-md-3 portfolio-item" role="listitem">
                            <div className="service-image service-image-6" role="img" aria-label="image showing handheld meter prefoming maintenance on an electical system"></div>
                            <div className="service-title">Maintenance</div>
                        </div>
                        <div className="col-md-3 portfolio-item">
                            {/* <!-- <div className="service-image service-image-1"></div>
                            <div className="service-title">some text</div> --> */}
                        </div>
                    </div>
                    {/* <!-- /.row --> */}
                </div>

            </div>
          </section>

    
          {/* <!-- Clients Aside --> */}
          <aside id="clients">
            <div className="container">

                <h1 className="section-title">Professional Installation and Maintenance</h1> 
                <div className="row client-wrap">
                    <div className="client-1 col-md-2"></div>
                    <div className="client-2 col-md-2"></div>
                    {/* <!--<div className="client-3 col-md-2"></div>--> */}
                    {/* <!--<div className="client-4 col-md-2"></div>--> */}
                    {/* <!--<div className="client-5 col-md-2"></div>--> */}
                    {/* <!--<div className="client-6 col-md-2"></div>--> */}
                </div>
            </div>
          </aside>  
      </div>
  );
}

import React, { Suspense, lazy, useContext } from 'react';

export default function Cabling() {

  return (
    <div>
        <div className="menu-spacer"></div>
        <section id="cabling">
            <div className="container intro">
                <h1 className="intro-header">Telephone &amp; Data</h1>
                <p className="intro-text">Lunaradi Electric is proud to be one of the largest providers of telephone and data services in the North Bay. Here are just some of the reasons why.</p>
            </div>
            <div className="container icon-list" role="list">
                <p className="icon-list-text" role="listitem">We offer a full range of telecommunications.</p>
                <p className="icon-list-text" role="listitem">Lunardi stays current an emerging technologies to assist your company in developing strategic technology planning. We provide solutions.</p>
                <p className="icon-list-text" role="listitem">We guarantee the best telecommunications service for your business. We'll work with you, or at your direction, to solve problems completely and in a timely manner.</p>
                <p className="icon-list-text" role="listitem">Lunardi is dedicated to providing state-of-the-art voice and data technologies to the business community. We operate as a systems integrator and cabling plant contractor. Lunardi offers its clients a total systems integration for both voice and data.</p>
                <p className="icon-list-text" role="listitem">Lunardi's voice and data network technicians are trained in layer connectivity and transmission media. We provide structured cabling systems for data, voice, image, security. low voltage and other emerging information technologies. As a premise wiring installer, we offer technical expertise uncommon in the industry. This gives Lunardi Electric the unique ability to provide not only the specific products and ser- vices your organization needs, but also system-wide premise solutions.</p>
                <p className="icon-list-text" role="listitem">Lunardi Electric welcomes the opportunity to earn your telecommunications and networking business. Some of our current clients include Korbel. Kendal Jackson, Sonoma County Office of Education, Napa Technologies High School, Bennett Valley School District, Santa Rosa City School District, and Rincon Valley School District.</p>
            </div>
        </section>
    </div>
  );
}

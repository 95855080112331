import React, { Suspense, lazy, useContext } from 'react';

export default function History() {

  return (
    <div>
        <div className="menu-spacer"></div>
        <section id="history">
            <div className="container intro" role="main">
                <h1 className="intro-header">History</h1>
                <p className="intro-text">Our business started with humble roots as early as 1946 as Esselink Electric. Joe Lunardi began as an apprentice at the shop in 1956 and worked his way up to a Journeyman Electrician in 1958. From Journeyman to Foreman, Joe worked his way up through the industry until 1964 when Joe was offered half of the business. Long-winded discussions between the family eventually led to Joe moving forward with the deal.</p>
                <p className="intro-text">Years of hard work bolstered the good name of what became Esselink - Lunardi, but in 1975 the complete transition was made and Joe Lunardi Electric, Inc. was in full force. Family roots are engrained in this Sonoma County business and the second generation of Lunardis are now running the shop. Joe still makes his visits to be sure everything is running smoothly. What is a true tale of the hardworking self-made American Dream, is now a life shared and loved by the Lunardi family that runs the business today.</p>
                <p className="intro-text">The same relationships that built Lunardi Electric from the ground up are what solidifies the trusted and professional relationships we share with our clients today.</p>
            </div>
        </section>
    </div>
  );
}

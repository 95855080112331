
import React, { Suspense, lazy, useContext } from 'react';
// import './home.css';

export default function Services() {

  return (
        <div>
            <div className="menu-spacer"></div>
            {/* <!-- About Section --> */}
            <section id="services" className="bg-light-gray">
                <div className="container intro">
                    <h1 className="intro-header">Services</h1>
                    <p className="intro-text">Lunardi provides complete electrical installations of industrial, commercial, educational (school) facilities as well as custom residential projects. Some of our previous clients for which we have offered our services:</p>
                </div>
                <div className="container icon-list" role="list" aria-label="list of some of Lunardi's commercial clients">
                    <p className="icon-list-text" role="listitem">Medtronic</p>
                    <p className="icon-list-text" role="listitem">Silver Oak Cellars</p>
                    <p className="icon-list-text" role="listitem">Keysight / Agilent</p>
                    <p className="icon-list-text" role="listitem">Elsie Allen High School</p>
                    <p className="icon-list-text" role="listitem">Maria Carrillo High School</p>
                </div>
                <br></br>
                <div className="container intro">
                    <p className="intro-text">Lunardi is well versed in any scale of electrical project. Whether we are lighting your favorite winery, or powering your child's school, we will continue to provide the highest level of support and quality to Sonoma County</p>
                    {/* <!--<p className="intro-text"></p>--> */}
                </div>
                <div className="container bullet-list-wrap">
                    <h2 className="bullet-list-header" style={{fontSize: '24px'}}>Lunardi is also capable of custom home electrical work as well as the following services:</h2>
                    <ul className="bullet-list" role="list" aria-label="some of Lunardi's home eletrical services">
                        <li role="listitem">Systems Analysis</li>
                        <li role="listitem">Network Cabling</li>
                        <li role="listitem">Telecommunications Applications</li>
                        <li role="listitem">Preventative Maintenance Programs</li>
                        <li role="listitem">Fiber Optic Cabling and Termination</li>
                        <li role="listitem">Fire Alarm and Security Systems</li>
                    </ul>
                </div>
            </section>
        </div>
  );
}













import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Nav from './components/nav';
import Footer from './components/footer';

import HomePage from './pages/home';
import Services from './pages/services'; 
import About from './pages/about'; 
import History from './pages/history'; 
import Projects from './pages/projects'; 
import Cabling from './pages/cabling'; 
import Contact from './pages/contact'; 

function App() {
  return (
      <div>
          <Nav></Nav>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about" element={<About />} />
              <Route path="/history" element={<History />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/cabling" element={<Cabling />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </BrowserRouter>
          <Footer />
      </div> 
  );
}
export default App;
import React, { Suspense, lazy, useContext } from 'react';

export default function Projects() {

  return (
    <div>
        <div className="menu-spacer"></div>
    
        <div className="container" role="main">
            <div className="container intro">
                <h1 className="intro-header">Projects</h1>
                <p className="intro-text">All of us at Lunardi Electric take pride in the work we've done.</p>
            </div>
            <div className="container icon-list" role="list" aria-label="Some of our customers">
                <p className="icon-list-text" role="listitem">Windsor High School</p>
                <p className="icon-list-text" role="listitem">Sonoma State University</p>
                <p className="icon-list-text" role="listitem">Next Level Communications</p>
                <p className="icon-list-text" role="listitem">Medtronics AVE</p>
                <p className="icon-list-text" role="listitem">New Horizons</p>
                <p className="icon-list-text" role="listitem">Pacific Bell</p>
                <p className="icon-list-text" role="listitem">Allied Domecq</p>
                <p className="icon-list-text" role="listitem">Grapevine Express</p>
                <p className="icon-list-text" role="listitem">Santa Rosa City Schools</p>
                <p className="icon-list-text" role="listitem">Cakebread Cellars</p>
                <p className="icon-list-text" role="listitem">Tonnellerie Radoux</p>
                <p className="icon-list-text" role="listitem">Filtration Group</p>
            </div>  
        </div>
    </div>
  );
}

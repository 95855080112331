import React, { Suspense, lazy, useContext, useEffect } from 'react';

export default function Nav() {


    useEffect(() => {
        let page = window.location.pathname.replace(/\//g,'');
        let nav = document.querySelector('nav');
        nav.dataset.page = (page !== '') ? page : 'home';

        window.addEventListener('scroll', function() {
            if (window.scrollY > 51) {
                nav.dataset.nav = ""
            } else {
                delete nav.dataset.nav;
            }
        });
    },[]);

  return (
    <nav id="mainNav" className="navbar navbar-default navbar-custom navbar-fixed-top affix-top">
        <div className="container">
            <div className="navbar-header page-scroll">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                    <span className="sr-only">Toggle navigation</span> Menu <i className="fa fa-bars"></i>
                </button>
                <div className="logo">
                    <a className="navbar-brand page-scroll" href="../" aria-label="Return Home"></a>
                </div>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul className="nav navbar-nav navbar-right" role="menubar">
                    <li className="active" role="menuitem">
                        <a className="page-scroll home" href="../">Home</a>
                    </li>
                    <li className="" role="menuitem">
                        <a className="page-scroll services" href="../services">Services</a>
                    </li>
                    <li className="" role="menuitem">
                        <a className="page-scroll about" href="../about">About</a>
                    </li>
                    <li className="" role="menuitem">
                        <a className="page-scroll history" href="../history">History</a>
                    </li>
                    <li className="" role="menuitem">
                        <a className="page-scroll projects" href="projects">Projects</a>
                    </li>
                    <li className="" role="menuitem">
                        <a className="page-scroll cabling" href="cabling">Cabling</a>
                    </li>
                    <li className="" role="menuitem">
                        <a className="page-scroll contact" href="contact">Contact</a>
                    </li>
                </ul>
                <div className="img-placeholder"></div>
            </div>
        </div>
    </nav>
  );
}

import React, { useEffect } from 'react';

export default function Contact() {

    const SITE_KEY = '6Lclb4oUAAAAAMiMGqrF0NseXL9IqBEOzaDJ6_U3';

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
        
            if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
                if (callback) callback();
            };
            document.body.appendChild(script);
            }
        
            if (isScriptExist && callback) callback();
        }
        
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            // console.log("Script loaded!");
        });
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Lclb4oUAAAAAMiMGqrF0NseXL9IqBEOzaDJ6_U3', {action: 'contact_form'})
            .then(function(token) {
                
                var formdata = JSON.stringify({
                    name      : document.querySelector('#name').value,
                    address   : document.querySelector('#address').value,
                    city      : document.querySelector('#city').value,
                    state     : document.querySelector('#state').value,
                    zip       : document.querySelector('#zip').value,
                    email     : document.querySelector('#email').value,
                    phone     : document.querySelector('#phone').value,
                    comment   : document.querySelector('#comment').value,
                    recaptcha : token
                });
                handlePost(formdata);
            });
        });
    }

    async function handlePost(body) {

        const rawResponse = await fetch('send', {
        method: 'POST',
            headers: {
            'Accept': 'text/html',
            'Content-Type': 'application/json'
            },
            body
        });
        // const content = await rawResponse.json();
        const content = await rawResponse.text();

        console.log('reply', content);
        document.querySelector('.message').innerHTML = content

    }

  return (
    <div>
        <div className="menu-spacer"></div>
        <section>
            <div className="container">
                
                <div id="contact" className="container">
                    <h1 className="intro-header">Contact Lunardi Electric</h1>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="page-contact-form" method="post" onSubmit={handleSubmit} aria-label="contact form">
                                <input type="text" aria-label="name" className="form-control" id="name" placeholder="Name" required="" />
                                <input type="email" aria-label="email" className="form-control" id="email" placeholder="Email" required="" />
                                <input type="tel" aria-label="phone" className="form-control" id="phone" placeholder="Phone" />
                                <input type="text" aria-label="address" className="form-control" id="address" placeholder="Address" />
                                <input type="text" aria-label="city" className="form-control" id="city" placeholder="City" />
                                <input type="text" aria-label="state" className="form-control" id="state" placeholder="State" />
                                <input type="number" aria-label="zip code" className="form-control" id="zip" placeholder="Zip" />
                                <textarea className="form-control" aria-label="text area" rows="4" id="comment"></textarea>
                                <button className="btn btn-primary btn-block" aria-label="submit button" type="submit">Submit</button>
                            </form>
                            <div className="message" style={{paddingTop: '10px', color: 'white'}}></div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
  );
}

import React, { Suspense, lazy, useContext } from 'react';

export default function HomeHeader() {

  const style = { aspectRatio:'125/46', width:'100%' };
  return (
    <header>
        <div style={{height:'auto'}}>

            <img style={style} src="../img/fork.jpg" alt="Lunardi Electric Jobsite - Massive commercial lighting project"/>


        </div>
    </header> 
  );
}

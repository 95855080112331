import React, { Suspense, lazy, useContext } from 'react';

export default function Footer() {

  const year = () => new Date().getFullYear();

  return (
    <footer>
        <div className="text-center">
        <img src="img/logo_foot.png" style={{maxWidth: '35%'}}className="" alt="Lunardi Logo" />
        </div>
        <div className="center phone"><span aria-label="Sebastopol phone number">(707)823-2129</span> | <span aria-label="Santa Rosa phone number">(707)545-4755</span></div>
        <div className="center address" aria-label="address">5334 Sebastopol Road Sebastopol, CA 95473</div>
        <div className="center fax">Fax (707)579-1757</div>
        <div className="center menu" role="menu">
            <a className="page-scroll" role="menuitem" href="../">Home</a><span className="divider">|</span>
            <a className="page-scroll" role="menuitem" href="../services">Services</a><span className="divider">|</span>
            <a className="page-scroll" role="menuitem" href="../about">About</a><span className="divider">|</span>
            <a className="page-scroll" role="menuitem" href="../history">History</a><span className="divider">|</span>
            <a className="page-scroll" role="menuitem" href="../projects">Projects</a><span className="divider">|</span>
            <a className="page-scroll" role="menuitem" href="../cabling">Cabling</a><span className="divider">|</span>
            <a className="page-scroll" role="menuitem" href="../contact">Contact</a>
        </div>
        <div className="center licence">©{year()} Lunardi Electric Inc. | Licence 315543</div>
    </footer>
  );
}

import React, { Suspense, lazy, useContext } from 'react';

export default function About() {

  return (
    <div>
        <div className="menu-spacer"></div>
        <section id="about">
            <div className="container intro" role="main">
                <h1 className="intro-header">About Us</h1>
                <p className="intro-text">Lunardi Electric has been providing premier electrical construction service in Sonoma County for over half a century. Joe Lunardi's reliability and integrity have made it the most sought-after company for over 60 years. Lunardi does approximately $10,000,000 a year in sales and is a union organization with a staff of over 40 electricians. Lunardi is also proud of its 24-hour emergency services and free estimates.</p>
                <p className="intro-text">Lunardi's staff consists of only the most highly qualified competent and courteous personnel. We use only the most sophisticated and high tech equipment to help them locate and repair the problem in the least amount of time with less inconvenience to the customer.</p>
                <p className="intro-text">Lunardi can deliver the most sophisticated equipment quickly, and there is no job too small to bid. Lunardi has negotiated contracts with many national accounts and some of the largest and most prestigious companies in Sonoma County who continue to use Lunardi for one reason: we're the best.</p>
                <p className="intro-text">As a diamond certified, National Electrical Contractors Association (NECA) member, we pride ourselves on providing clients with trusted, tried, and respectable electrical support not found anywhere else in Sonoma County.</p>
            </div>
            <div className="container icon-list" role="list" aria-label="Lunardi Electric">
                <p className="icon-list-text" role="listitem">40 certified union electricians provide the expertise you need to complete any job on time and within budget.</p>
                <p className="icon-list-text" role="listitem">Even at our competitive prices, we can always provide the best person for the job and the highest quality material available.</p>
                <p className="icon-list-text" role="listitem">The largest inventory of parts in Sonoma County means we have the parts you need in stock.</p>
                <p className="icon-list-text" role="listitem">Our design build capabilities allow us to tackle the most complex structure and create the most cost-effective solution.</p>
                <p className="icon-list-text" role="listitem">The most advanced and sophisticated test equipment allow Lunardi to detect and find the problem in the shortest possible time.</p>
            </div>
        </section>
    </div>
  );
}
